import React, { useState, useEffect } from 'react';

function Users() {
    const [allLastLogins, setAllLastLogins] = useState([]);
    const [totalFalseNotifications, setTotalFalseNotifications] = useState(0);
    const [totalUniqueDevices, setTotalUniqueDevices] = useState(0);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        const falseNotificationsCount = allLastLogins.filter(lastLogin => {
            if (selectedGroupId) {
                const groupId = lastLogin.deviceId.split('-')[0];
                return groupId === selectedGroupId && (lastLogin.notification === false || lastLogin.notification === 0);
            }
            return lastLogin.notification === false || lastLogin.notification === 0;
        }).length;
        setTotalFalseNotifications(falseNotificationsCount);

        const uniqueDevices = new Set();
        allLastLogins.forEach(lastLogin => {
            if (!selectedGroupId || lastLogin.deviceId.startsWith(selectedGroupId)) {
                uniqueDevices.add(lastLogin.deviceId);
            }
        });
        setTotalUniqueDevices(uniqueDevices.size);
    }, [allLastLogins, selectedGroupId]);

    const getAllLastLogins = async () => {
        try {
            const response = await fetch('https://tracker.cialiscr.com/getAllLastLogins');
            const data = await response.json();
            setAllLastLogins(data.allLastLogins || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            document.getElementById('allLastLogins').innerHTML = `<tr><td colspan="4">Error fetching data. Please try again.</td></tr>`;
        }
    };

    const formatTimestamp = (timestamp) => {
        if (timestamp && typeof timestamp === 'string') {
            const date = new Date(timestamp);
            const formattedDateTime = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
            return formattedDateTime;
        } else {
            return timestamp;
        }
    };

    const handleRadioChange = (e) => {
        setSelectedGroupId(e.target.value);
    };

    const groupFilters = ['sicepat', 'pintu', 'tangga', 'es', 'gelas', 'lampu', 'miki', 'nyai', 'singa'];

    return (
        <div>
            <h1>Users</h1>
            <button onClick={getAllLastLogins}>Get All Users</button>
            <h2>User data:</h2>
            <div className="summary">
                <strong>Total Notifications Not Allowed:</strong> {totalFalseNotifications}<br />
                <strong>Total Users (Devices):</strong> {totalUniqueDevices}
            </div>
            <div>
                <input type="radio" id="all" name="group" value="" onChange={handleRadioChange} checked={!selectedGroupId} />
                <label htmlFor="all">All</label>
            </div>
            {groupFilters.map((filter, index) => (
                <div key={index}>
                    <input type="radio" id={`group${index}`} name="group" value={filter} onChange={handleRadioChange} checked={selectedGroupId === filter} />
                    <label htmlFor={`group${index}`}>{filter}</label>
                </div>
            ))}
            <table id="allLastLoginsTable" border="1">
                <thead>
                    <tr>
                        <th>Device ID</th>
                        <th>Last Login</th>
                        <th>App Version</th>
                        <th>Notification</th>
                    </tr>
                </thead>
                <tbody id="allLastLogins">
                    {allLastLogins
                        .filter(lastLogin => !selectedGroupId || lastLogin.deviceId.startsWith(selectedGroupId))
                        .map(lastLogin => (
                            <tr key={lastLogin.deviceId}>
                                <td>{lastLogin.deviceId}</td>
                                <td>{formatTimestamp(lastLogin.lastLogin)}</td>
                                <td>{lastLogin.appVersion}</td>
                                <td>{lastLogin.notification !== null ? lastLogin.notification.toString() : 'default true'}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default Users;
