import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
      <div style={{ width: '200px', backgroundColor: '#f0f0f0', padding: '20px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>
            <Link to="/online-users">Online Users</Link>
          </li>
          <li>
            <Link to="/logs">Logs</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
        </ul>
        <button onClick={handleLogout}>Logout</button>
      </div>
  );
};

export default Sidebar;