import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes from react-router-dom
import Login from './components/Login';
import Home from './components/Home';
import LogsViewer from './components/LogsViewer';
import Users from './components/Users';
import OnlineUsers from './components/OnlineUsers';
import Sidebar from './components/Sidebar';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in and if the session has expired
    const storedLoginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (storedLoginInfo) {
      const { isLoggedIn, loginTime } = storedLoginInfo;
      const expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const currentTime = new Date().getTime();
      if (isLoggedIn && (currentTime - loginTime) < expirationTime) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  const handleLogin = (status) => {
    const loginTime = new Date().getTime();
    localStorage.setItem('loginInfo', JSON.stringify({ isLoggedIn: true, loginTime }));

    setIsLoggedIn(status);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    localStorage.removeItem('loginInfo');

    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
<>
    <Router>
{isLoggedIn && <Sidebar onLogout={handleLogout} />}
      <div>
        <Routes> {/* Wrap Routes around Route components */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          {/* <Route path="/" element={isLoggedIn ? <Home onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} /> */}
          <Route path="/" element={isLoggedIn ? <OnlineUsers onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} />
          <Route path="/online-users" element={isLoggedIn ? <OnlineUsers /> : <Login onLogin={handleLogin} /> } />
        <Route path="/logs" element={isLoggedIn ? <LogsViewer /> : <Login onLogin={handleLogin} /> } />
        <Route path="/users" element={isLoggedIn ? <Users /> : <Login onLogin={handleLogin} /> } />
        </Routes>
      </div>
    </Router>
</>
  );
};

export default App;

