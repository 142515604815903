import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault()
    
    try {
      const response = await axios.post('https://tracker.cialiscr.com/login', {
        username,
        password
      });
      console.log(response.data);
      onLogin(true);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
<div>
  <h2>Login</h2>
  <form onSubmit={handleLogin}>
    <input
      type="text"
      defaultValue={username}
      onChange={(e) => setUsername(e.target.value)}
      placeholder="Username"
    />
    <input
      type="password"
      defaultValue={password}
      onChange={(e) => setPassword(e.target.value)}
      placeholder="Password"
    />
    <button type="submit">Login</button>
  </form>
</div>

  );
};

export default Login;